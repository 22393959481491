import {
  Charger,
  ChargerBase,
  ChargerRef,
  ChargerListings,
  ChargersPerTenant,
  ServiceStatus,
  ChargerUptime,
} from '../models/charger'
import moment from 'moment'
import { mockListing } from './listing-mock'
import { ChargersResponse, ChargersUptimeForTenantResponse } from '../models/http'
import { ConnectorServiceStatus, ConnectorStatus, connectorServiceStatus, connectorStatus } from 'models/connector'
import { mockPrice } from './price-mock'
import { mockLoiterings } from './loitering-mock'
import { ChargersPerTenantFilter } from 'models/filter'
import { mockListingTaggingResp, mockTenantTaggingResp } from './tagging-mock'
import { log } from 'logger'
import { mockTenantsRef } from 'services/data-provider/mock'
import { mockPermission } from './permission-mock'
import { mockVendors } from './vendor'
import { generatedMoment, modifiedMoment } from 'helpers/date/datetime'

export const mockChargerRef: { [serialNumber: string]: ChargerRef } = {
  '1EC1-1-1824-00035': {
    id: 1,
    chargePointSerialNumber: '1EC1-1-1824-00035',
  },
  '1EC1-1-1834-00188': {
    id: 2,
    chargePointSerialNumber: '1EC1-1-1834-00188',
  },
  '1EC1-1-1834-00185': {
    id: 3,
    chargePointSerialNumber: '1EC1-1-1834-00185',
  },
  '1EC0-1-1823-00061': {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
  },
  '1EC0-1-1823-00063': {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00063',
  },
  '1920511': {
    id: 5,
    chargePointSerialNumber: '1920511',
  },
  '1EC1-1-1834-00059': {
    id: 6,
    chargePointSerialNumber: '1EC1-1-1834-00059',
  },
  '1EC1-1-1834-00060': {
    id: 7,
    chargePointSerialNumber: '1EC1-1-1834-00060',
  },
  '1EC1-1-1834-1312': {
    id: 8,
    chargePointSerialNumber: '1EC1-1-1834-1312',
  },
  '1EC1-1-1834-1313': {
    id: 9,
    chargePointSerialNumber: '1EC1-1-1834-1313',
  },
  '5NC1-6-2251-00160': {
    id: 10,
    chargePointSerialNumber: '5NC1-6-2251-00160',
  },
  '5NC1-6-2251-00113': {
    id: 11,
    chargePointSerialNumber: '5NC1-6-2251-00113',
  },
  '5NC1-6-2251-00125': {
    id: 12,
    chargePointSerialNumber: '5NC1-6-2251-00125',
  },
  '5NC1-6-2251-00123': {
    id: 13,
    chargePointSerialNumber: '5NC1-6-2251-00123',
  },
  '5NC1-6-2251-00114': {
    id: 14,
    chargePointSerialNumber: '5NC1-6-2251-00114',
  },
  DSWU122: {
    id: 300,
    chargePointSerialNumber: 'DSWU122',
  },
  DSWU123: {
    id: 300,
    chargePointSerialNumber: 'DSWU123',
  },
  DSWU124: {
    id: 300,
    chargePointSerialNumber: 'DSWU124',
  },
  DSWU125: {
    id: 300,
    chargePointSerialNumber: 'DSWU125',
  },
}

export const mockCharger: { [serialNumber: string]: ChargerBase | Charger } = {
  '1EC1-1-1824-00035': {
    ...mockChargerRef['1EC1-1-1824-00035'],
    serviceStatus: 'under_repair',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenantsRef[0],
    online: false,
    chargingType: 'Level 2',
  },
  '1EC1-1-1834-00188': {
    ...mockChargerRef['1EC1-1-1834-00188'],
    serviceStatus: 'activating',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenantsRef[0],
    online: true,
    chargingType: 'DC',
  },
  '1EC1-1-1834-00185': {
    ...mockChargerRef['1EC1-1-1834-00185'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenantsRef[0],
    online: true,
    chargingType: 'Level 2',
  },
  '1EC0-1-1823-00061': {
    ...mockChargerRef['1EC0-1-1823-00061'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenantsRef[2],
    online: true,
    chargingType: 'Level 2',
  },
  '1EC0-1-1823-00063': {
    ...mockChargerRef['1EC0-1-1823-00063'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenantsRef[3],
    online: true,
    chargingType: 'Level 2',
  },
  '1920511': {
    ...mockChargerRef['1920511'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'EV-BOX',
    tenant: mockTenantsRef[3],
    online: false,
    chargingType: 'Level 2',
  },
  '1EC1-1-1834-00059': {
    ...mockChargerRef['1EC1-1-1834-00059'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenantsRef[1],
    online: true,
    chargingType: 'DC',
  },
  '1EC1-1-1834-00060': {
    ...mockChargerRef['1EC1-1-1834-00060'],
    serviceStatus: 'in_maintenance',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenantsRef[0],
    online: false,
    chargingType: 'Level 2',
  },
  '1EC1-1-1834-1312': {
    ...mockChargerRef['1EC1-1-1834-1312'],
    chargePointModel: 'EX-1193-1A',
    chargePointVendor: 'Zerova 120kW',
    online: false,
    chargingType: 'Level 2',
    chargerType: 'single-port',
    serviceStatus: 'in_maintenance' as ServiceStatus,
    firmwareVersion: 'V1.02.21 (BBOX: V00.04.30.00)',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: '2024-05-15T13:48:51.000Z',
  },
  '5NC1-6-2251-00160': {
    ...mockChargerRef['5NC1-6-2251-00160'],
    chargePointModel: 'EX-1762-5NC1',
    chargePointVendor: 'LITE-ON',
    online: false,
    chargingType: 'Level 2',
    chargerType: 'single-port',
    serviceStatus: 'in_maintenance' as ServiceStatus,
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: '2024-05-15T13:48:51.000Z',
  },
  '5NC1-6-2251-00113': {
    ...mockChargerRef['5NC1-6-2251-00113'],
    chargePointModel: 'EX-1762-5NC1',
    chargePointVendor: 'LITE-ON',
    online: true,
    chargingType: 'Level 2',
    chargerType: 'single-port',
    serviceStatus: 'service_mode' as ServiceStatus,
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: modifiedMoment(13, 48, 51, 'subtract').format(),
  },
  '5NC1-6-2251-00125': {
    ...mockChargerRef['5NC1-6-2251-00125'],
    chargePointModel: 'EX-1762-5NC1',
    chargePointVendor: 'LITE-ON',
    online: true,
    chargingType: 'Level 2',
    chargerType: 'single-port',
    serviceStatus: 'service_mode' as ServiceStatus,
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: modifiedMoment(13, 48, 51, 'subtract').format(),
  },
  '5NC1-6-2251-00123': {
    ...mockChargerRef['5NC1-6-2251-00123'],
    chargePointModel: 'EX-1762-5NC1',
    chargePointVendor: 'LITE-ON',
    online: true,
    chargingType: 'Level 2',
    chargerType: 'single-port',
    serviceStatus: 'service_mode' as ServiceStatus,
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: modifiedMoment(13, 48, 51, 'subtract').format(),
  },
  '5NC1-6-2251-00114': {
    ...mockChargerRef['5NC1-6-2251-00114'],
    chargePointModel: 'EX-1762-5NC1',
    chargePointVendor: 'LITE-ON',
    online: true,
    chargingType: 'Level 2',
    chargerType: 'single-port',
    serviceStatus: 'service_mode' as ServiceStatus,
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: modifiedMoment(13, 48, 51, 'subtract').format(),
  },
  DSWU122: {
    ...mockChargerRef['DSWU122'],
    chargePointModel: 'DSWU122T0TD6WN',
    chargePointVendor: 'Zerova 120kW',
    online: true,
    chargingType: 'DC',
    chargerType: 'multi-port',
    serviceStatus: 'service_mode' as ServiceStatus,
    firmwareVersion: 'V3.04.08.0122.WN',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: modifiedMoment(13, 48, 51, 'subtract').format(),
  },
  DSWU123: {
    ...mockChargerRef['DSWU123'],
    chargePointModel: 'DSWU122T0TD6WN',
    chargePointVendor: 'Zerova 120kW',
    online: true,
    chargingType: 'DC',
    chargerType: 'multi-port',
    serviceStatus: 'service_mode' as ServiceStatus,
    firmwareVersion: 'V3.04.08.0122.WN',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: modifiedMoment(13, 48, 51, 'subtract').format(),
  },
  DSWU124: {
    ...mockChargerRef['DSWU124'],
    chargePointModel: 'DSWU122T0TD6WN',
    chargePointVendor: 'Zerova 120kW',
    online: true,
    chargingType: 'DC',
    chargerType: 'multi-port',
    serviceStatus: 'service_mode' as ServiceStatus,
    firmwareVersion: 'V3.04.08.0122.WN',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: modifiedMoment(13, 48, 51, 'subtract').format(),
  },
  DSWU125: {
    ...mockChargerRef['DSWU125'],
    chargePointModel: 'DSWU122T0TD6WN',
    chargePointVendor: 'Zerova 120kW',
    online: true,
    chargingType: 'DC',
    chargerType: 'multi-port',
    serviceStatus: 'service_mode' as ServiceStatus,
    firmwareVersion: 'V3.04.08.0122.WN',
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: modifiedMoment(13, 48, 51, 'subtract').format(),
  },
}
export const mockFullCharger = {
  ...mockCharger['1EC1-1-1824-00035'],
  connectors: [
    {
      id: 1,
      connectorId: 1,
      listing: mockListing['DP01'],
      state: 'SuspendedEV',
      lastStatusChangedAt: moment().startOf('hour').format(),
    },
    {
      id: 2,
      connectorId: 2,
      listing: mockListing['DP02'],
      state: 'Charging',
      lastStatusChangedAt: moment().startOf('hour').format(),
    },
  ],
  firmwareVersion: '2',
  chargerType: 'single-port',
  serviceStatus: 'service_mode',
  maxWatt: 1700,
  lastConnectedAt: moment().startOf('week').format(),
  lastDisconnectedAt: moment().startOf('month').format(),

  createdAt: moment().format(),
  updatedAt: moment().format(),
} as Charger

export const mockChargerById: { [chargerId: number]: Charger } = {
  1: {
    ...mockCharger['1EC1-1-1824-00035'],
    connectors: [
      {
        id: 1,
        listing: mockListing['DP01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'SuspendedEV',
        serviceStatus: 'Finishing',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  2: {
    ...mockCharger['1EC1-1-1834-00188'],
    connectors: [
      {
        id: 9,
        listing: mockListing['YK01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Available',
        serviceStatus: 'troubleshooting',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  3: {
    ...mockCharger['1EC1-1-1834-00185'],
    connectors: [
      {
        id: 3,
        listing: mockListing['NA01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Preparing',
        serviceStatus: 'on_hold',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  63: {
    ...mockCharger['1EC0-1-1823-00061'],
    connectors: [
      {
        id: 4,
        listing: mockListing['BN01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Finishing',
        serviceStatus: 'driver_activating',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  5: {
    ...mockCharger['1920511'],
    connectors: [
      {
        id: 5,
        listing: mockListing['BY01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Unavailable',
        serviceStatus: 'awaiting_commissioning',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  6: {
    ...mockCharger['1EC1-1-1834-00059'],
    connectors: [
      {
        id: 6,
        listing: mockListing['DN01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Charging',
        serviceStatus: 'service_mode',
      },
      {
        id: 7,
        connectorId: 2,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Faulted',
        serviceStatus: 'under_repair',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  7: {
    ...mockCharger['1EC1-1-1834-00060'],
    connectors: [
      {
        id: 8,
        listing: mockListing['DC01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Available',
        serviceStatus: 'activating',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  10: {
    ...mockCharger['5NC1-6-2251-00160'],
    connectors: [
      {
        id: 105,
        connectorId: 1,
        serviceStatus: connectorServiceStatus[0],
        state: connectorStatus[0],
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
    ],
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: '2024-05-15T13:48:51.000Z',
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  300: {
    ...mockCharger['DSWU122'],
    connectors: [
      {
        id: 300,
        connectorId: 1,
        serviceStatus: 'active',
        state: 'Available',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
    ],
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: '2024-05-15T13:48:51.000Z',
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  301: {
    ...mockCharger['DSWU123'],
    connectors: [
      {
        id: 300,
        connectorId: 1,
        serviceStatus: 'active',
        state: 'Available',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
    ],
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: '2024-05-15T13:48:51.000Z',
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  302: {
    ...mockCharger['DSWU124'],
    connectors: [
      {
        id: 300,
        connectorId: 1,
        serviceStatus: 'active',
        state: 'Available',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
    ],
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: '2024-05-15T13:48:51.000Z',
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  303: {
    ...mockCharger['DSWU125'],
    connectors: [
      {
        id: 300,
        connectorId: 1,
        serviceStatus: 'active',
        state: 'Available',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
    ],
    firmwareVersion: 'V1.00.90NEDCO (BBOX: V1.33)',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: '2024-05-15T13:48:51.000Z',
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
}

export const mockChargers = [
  {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
  },
  {
    id: 64,
    chargePointSerialNumber: '1EC0-1-1823-00062',
  },
  {
    id: 65,
    chargePointSerialNumber: '1EC0-1-1823-00063',
  },
  {
    id: 5,
    chargePointSerialNumber: '1920511',
  },
  {
    id: 10,
    chargePointSerialNumber: '5NC1-6-2251-00160',
  },
  {
    id: 11,
    chargePointSerialNumber: '5NC1-6-2251-00113',
  },
  {
    id: 12,
    chargePointSerialNumber: '5NC1-6-2251-00125',
  },
  {
    id: 13,
    chargePointSerialNumber: '5NC1-6-2251-00123',
  },
  {
    id: 14,
    chargePointSerialNumber: '5NC1-6-2251-00114',
  },
] as ChargerRef[]

export const mockChargersListings = [
  {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
    listings: [{ id: 2, title: 'FR02' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 52,
    chargePointSerialNumber: 'OCPIT2',
    listings: [{ id: 47, title: 'OpenADRTestListing3' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 51,
    chargePointSerialNumber: 'OCPIT1',
    listings: [{ id: 45, title: 'OpenADRTestListing' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 48,
    chargePointSerialNumber: 'PHIWIFI',
    listings: [{ id: 4, title: 'TV01' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 29,
    chargePointSerialNumber: '1EC3-1-1917-00010',
    listings: [{ id: 3, title: 'FR03' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
] as ChargerListings[]

export const mockMultiFamily1Chargers = {
  tenant: mockTenantsRef[0],
  listings: [
    {
      ...mockListing['L6652'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('hour').format(),
      kind: 'non_reservation',
      charger: mockCharger['5NC1-6-2251-00160'],
      connector: {
        id: 105,
        connectorId: 1,
        serviceStatus: connectorServiceStatus[0],
        ocppStatus: connectorStatus[0],
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
      price: mockPrice.hourlyPricing,
      loitering: mockLoiterings[0],
      permission: mockPermission.privateHidden,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L6653'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('day').format(),
      kind: 'reservation',
      charger: mockCharger['5NC1-6-2251-00113'],
      connector: {
        id: 106,
        connectorId: 1,
        serviceStatus: connectorServiceStatus[3],
        ocppStatus: connectorStatus[0],
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
      price: mockPrice.hourlyPricing,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(14, 30, 0, 'subtract').format(),
      permission: mockPermission.privateHidden,
      tags: mockTenantTaggingResp,
    },
    {
      ...mockListing['L6654'],
      connectivity: true,
      kind: 'reservation',
      charger: mockCharger['5NC1-6-2251-00125'],
      connector: {
        id: 107,
        connectorId: 1,
        serviceStatus: 'driver_activating' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
      price: mockPrice.hourlyPricing,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(15, 45, 0, 'subtract').format(),
      permission: mockPermission.privateHidden,
      tags: mockTenantTaggingResp,
    },
    {
      ...mockListing['L6655'],
      connectivity: true,
      kind: 'reservation',
      charger: mockCharger['5NC1-6-2251-00123'],
      connector: {
        id: 108,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').add(21, 'minute').format(),
      },
      price: mockPrice.hourlyPricing,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(15, 45, 0, 'subtract').format(),
      permission: mockPermission.privateHidden,
      tags: mockTenantTaggingResp,
    },
    {
      ...mockListing['L6656'],
      connectivity: true,
      kind: 'non_reservation',
      charger: mockCharger['5NC1-6-2251-00114'],
      connector: {
        id: 109,
        connectorId: 1,
        serviceStatus: 'driver_activating' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').add(35, 'minute').format(),
      },
      price: mockPrice.hourlyPricing,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(15, 45, 0, 'subtract').format(),
      permission: mockPermission.privateHidden,
      tags: mockTenantTaggingResp,
    },
    {
      ...mockListing['L6657'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('hour').format(),
      kind: 'non_reservation',
      charger: mockCharger['5NC1-6-2251-00160'],
      connector: {
        id: 105,
        connectorId: 1,
        serviceStatus: connectorServiceStatus[0],
        ocppStatus: connectorStatus[0],
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
      price: mockPrice.hourlyPricing,
      loitering: mockLoiterings[0],
      permission: mockPermission.privateHidden,
      tags: mockListingTaggingResp,
    },
  ],
} as ChargersPerTenant

export const mockMultiFamily2Chargers = {
  tenant: mockTenantsRef[1],
  listings: [
    {
      ...mockListing['L6658'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('day').format(),
      kind: 'non_reservation',
      charger: mockCharger['5NC1-6-2251-00113'],
      connector: {
        id: 106,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'SuspendedEV' as ConnectorStatus,
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
      price: mockPrice.hourlyPricing150,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(14, 30, 0, 'subtract').format(),
      permission: mockPermission.publicVisible,
      tags: mockTenantTaggingResp,
    },
    {
      ...mockListing['L6659'],
      connectivity: true,
      kind: 'non_reservation',
      charger: mockCharger['5NC1-6-2251-00125'],
      connector: {
        id: 107,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'SuspendedEV' as ConnectorStatus,
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').format(),
      },
      price: mockPrice.hourlyPricing150,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(15, 45, 0, 'subtract').format(),
      permission: mockPermission.publicVisible,
      tags: mockTenantTaggingResp,
    },
    {
      ...mockListing['L6660'],
      connectivity: true,
      kind: 'non_reservation',
      charger: mockCharger['5NC1-6-2251-00123'],
      connector: {
        id: 108,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Charging' as ConnectorStatus,
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').add(21, 'minute').format(),
      },
      price: mockPrice.hourlyPricing150,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(15, 52, 0, 'subtract').format(),
      permission: mockPermission.publicVisible,
      tags: mockTenantTaggingResp,
    },
    {
      ...mockListing['L6661'],
      connectivity: true,
      kind: 'non_reservation',
      charger: mockCharger['5NC1-6-2251-00114'],
      connector: {
        id: 109,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').add(35, 'minute').format(),
      },
      price: mockPrice.freePricing,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(15, 45, 0, 'subtract').format(),
      permission: mockPermission.privateHidden,
      tags: mockTenantTaggingResp,
    },
    {
      ...mockListing['L6662'],
      connectivity: true,
      kind: 'non_reservation',
      charger: mockCharger['5NC1-6-2251-00114'],
      connector: {
        id: 109,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').add(35, 'minute').format(),
      },
      price: mockPrice.freePricing,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(15, 45, 0, 'subtract').format(),
      permission: mockPermission.privateHidden,
      tags: mockTenantTaggingResp,
    },
    {
      ...mockListing['L6663'],
      connectivity: true,
      kind: 'non_reservation',
      charger: mockCharger['5NC1-6-2251-00114'],
      connector: {
        id: 109,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Charging' as ConnectorStatus,
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('hour').add(35, 'minute').format(),
      },
      price: mockPrice.freePricing,
      loitering: mockLoiterings[0],
      serviceStatusChangedSince: modifiedMoment(15, 45, 0, 'subtract').format(),
      permission: mockPermission.privateHidden,
      tags: mockTenantTaggingResp,
    },
  ],
} as ChargersPerTenant

export const mockWorkPlaceCommercialChargers = {
  tenant: mockTenantsRef[2],
  listings: [
    {
      ...mockListing['L7715'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('day').format(),
      kind: 'non_reservation',
      charger: {
        id: 7,
        chargePointSerialNumber: '1EC1-1-1834-00060',
        chargePointModel: 'EX-1762-5SW1',
        chargePointVendor: 'LITE-ON',
        online: false,
        chargingType: 'Level 2',
        chargerType: 'single-port',
        serviceStatus: 'activating',
        firmwareVersion: 'P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('week').format(),
        lastDisconnectedAt: generatedMoment.startOf('hour').format(),
      },
      connector: {
        id: 200,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Charging',
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('week').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7716'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('week').format(),
      kind: 'non_reservation',
      charger: {
        id: 8,
        chargePointSerialNumber: '1EC1-1-1834-00043',
        chargePointModel: 'EX-1762-5SW1',
        chargePointVendor: 'LITE-ON',
        online: true,
        chargingType: 'Level 2',
        chargerType: 'single-port',
        serviceStatus: 'under_repair' as ServiceStatus,
        firmwareVersion: '	P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('week').format(),
        lastDisconnectedAt: generatedMoment.startOf('month').format(),
      },
      connector: {
        id: 201,
        connectorId: 1,
        serviceStatus: 'property_activating' as ConnectorServiceStatus,
        ocppStatus: 'SuspendedEVSE' as ConnectorStatus,
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('minute').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7717'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('month').format(),
      kind: 'non_reservation',
      charger: {
        id: 9,
        chargePointSerialNumber: '1EC1-1-1834-00044',
        chargePointModel: 'EX-1762-5SW1',
        chargePointVendor: 'LITE-ON',
        online: true,
        chargingType: 'Level 2',
        chargerType: 'single-port',
        serviceStatus: 'under_repair' as ServiceStatus,
        firmwareVersion: '	P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('week').format(),
        lastDisconnectedAt: generatedMoment.startOf('minute').subtract(3, 'minute').format(),
      },
      connector: {
        id: 202,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('minute').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7718'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('week').format(),
      kind: 'non_reservation',
      charger: {
        id: 10,
        chargePointSerialNumber: '1EC1-1-1834-00045',
        chargePointModel: 'EX-1762-5SW1',
        chargePointVendor: 'LITE-ON',
        online: true,
        chargingType: 'Level 2',
        chargerType: 'single-port',
        serviceStatus: 'under_repair' as ServiceStatus,
        firmwareVersion: 'P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('week').format(),
        lastDisconnectedAt: generatedMoment.startOf('month').format(),
      },
      connector: {
        id: 203,
        connectorId: 1,
        serviceStatus: 'commissioned' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'J1772',
        lastStatusChangedAt: generatedMoment.startOf('minute').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7719'],
      connectivity: false,
      kind: 'reservation',
      charger: {
        id: 9,
        chargePointSerialNumber: '1EC1-1-1834-00046',
        chargePointModel: 'AWLU770001T2P1',
        chargePointVendor: 'Zerova 120kW',
        online: true,
        chargingType: 'DC',
        chargerType: 'single-port',
        serviceStatus: 'service_mode' as ServiceStatus,
        firmwareVersion: 'P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('minute').format(),
        lastDisconnectedAt: moment().startOf('day').format(),
      },
      connector: {
        id: 204,
        connectorId: 1,
        serviceStatus: 'under_repair' as ConnectorServiceStatus,
        ocppStatus: 'Faulted',
        connectorType: 'CHAdeMO',
        lastStatusChangedAt: generatedMoment.startOf('hour').subtract(23, 'minute').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7720'],
      connectivity: true,
      kind: 'reservation',
      charger: {
        id: 9,
        chargePointSerialNumber: '1EC1-1-1834-00047',
        chargePointModel: 'AWLU770001T2P1',
        chargePointVendor: 'Zerova 120kW',
        online: true,
        chargingType: 'DC',
        chargerType: 'single-port',
        serviceStatus: 'service_mode' as ServiceStatus,
        firmwareVersion: 'P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('minute').format(),
        lastDisconnectedAt: moment().startOf('day').format(),
      },
      connector: {
        id: 205,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Preparing',
        connectorType: 'CHAdeMO',
        lastStatusChangedAt: generatedMoment.startOf('hour').subtract(23, 'minute').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7721'],
      connectivity: true,
      kind: 'reservation',
      charger: {
        id: 9,
        chargePointSerialNumber: '1EC1-1-1834-00048',
        chargePointModel: 'AWLU770001T2P1',
        chargePointVendor: 'Zerova 120kW',
        online: true,
        chargingType: 'DC',
        chargerType: 'single-port',
        serviceStatus: 'service_mode' as ServiceStatus,
        firmwareVersion: 'P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('minute').format(),
        lastDisconnectedAt: moment().startOf('day').format(),
      },
      connector: {
        id: 206,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Finishing',
        connectorType: 'CCS',
        lastStatusChangedAt: generatedMoment.startOf('hour').subtract(23, 'minute').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7722'],
      connectivity: true,
      kind: 'reservation',
      charger: {
        id: 9,
        chargePointSerialNumber: '1EC1-1-1834-00049',
        chargePointModel: 'AWLU770001T2P1',
        chargePointVendor: 'Zerova 120kW',
        online: true,
        chargingType: 'DC',
        chargerType: 'single-port',
        serviceStatus: 'service_mode' as ServiceStatus,
        firmwareVersion: 'P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('minute').format(),
        lastDisconnectedAt: moment().startOf('day').format(),
      },
      connector: {
        id: 207,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'CCS',
        lastStatusChangedAt: generatedMoment.startOf('hour').subtract(23, 'minute').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7723'],
      connectivity: true,
      kind: 'reservation',
      charger: {
        id: 9,
        chargePointSerialNumber: '1EC1-1-1834-00050',
        chargePointModel: 'AWLU770001T2P1',
        chargePointVendor: 'Zerova 120kW',
        online: true,
        chargingType: 'DC',
        chargerType: 'single-port',
        serviceStatus: 'service_mode' as ServiceStatus,
        firmwareVersion: 'P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('minute').format(),
        lastDisconnectedAt: moment().startOf('day').format(),
      },
      connector: {
        id: 207,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'CCS',
        lastStatusChangedAt: generatedMoment.startOf('hour').subtract(23, 'minute').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7724'],
      connectivity: true,
      kind: 'reservation',
      charger: {
        id: 9,
        chargePointSerialNumber: '1EC1-1-1834-00051',
        chargePointModel: 'AWLU770001T2P1',
        chargePointVendor: 'Zerova 120kW',
        online: true,
        chargingType: 'DC',
        chargerType: 'single-port',
        serviceStatus: 'service_mode' as ServiceStatus,
        firmwareVersion: 'P0134B0125v7_656.190711',
        lastConnectedAt: generatedMoment.startOf('minute').format(),
        lastDisconnectedAt: moment().startOf('day').format(),
      },
      connector: {
        id: 208,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'CCS',
        lastStatusChangedAt: generatedMoment.startOf('hour').subtract(23, 'minute').format(),
      },
      price: mockPrice.kWhPricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
  ],
} as ChargersPerTenant

export const mockDCFCChargers = {
  tenant: mockTenantsRef[3],
  listings: [
    {
      ...mockListing['L7725'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('day').format(),
      kind: 'non_reservation',
      charger: mockCharger['DSWU122'],
      connector: {
        id: 300,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'CCS',
        lastStatusChangedAt: moment().startOf('hour').subtract(3, 'minute').format(),
      },
      price: mockPrice.kWh025Pricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7726'],
      connectivity: true,
      lastConnectivityAt: generatedMoment.startOf('day').format(),
      kind: 'non_reservation',
      charger: mockCharger['DSWU122'],
      connector: {
        id: 301,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'CCS',
        lastStatusChangedAt: moment().startOf('hour').subtract(4, 'hour').format(),
      },
      price: mockPrice.kWh025Pricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7727'],
      connectivity: true,
      lastConnectivityAt: moment().startOf('day').format(),
      kind: 'non_reservation',
      charger: mockCharger['DSWU123'],
      connector: {
        id: 302,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'CCS',
        lastStatusChangedAt: moment().startOf('hour').subtract(3, 'hour').format(),
      },
      price: mockPrice.kWh025Pricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
    {
      ...mockListing['L7728'],
      connectivity: true,
      lastConnectivityAt: moment().startOf('day').format(),
      kind: 'non_reservation',
      charger: mockCharger['DSWU123'],
      connector: {
        id: 303,
        connectorId: 1,
        serviceStatus: 'active' as ConnectorServiceStatus,
        ocppStatus: 'Available',
        connectorType: 'CCS',
        lastStatusChangedAt: moment().startOf('hour').subtract(23, 'minute').format(),
      },
      price: mockPrice.kWh025Pricing,
      loitering: mockLoiterings[4],
      permission: mockPermission.publicVisible,
      tags: mockListingTaggingResp,
    },
  ],
} as ChargersPerTenant

export const mockChargersV2 = [
  { ...mockMultiFamily1Chargers },
  { ...mockMultiFamily2Chargers },
  { ...mockWorkPlaceCommercialChargers },
  { ...mockDCFCChargers },
] as ChargersPerTenant[]

const mockChargersUptimeForTenant = [
  {
    id: 1,
    chargePointSerialNumber: '1EC1-1-1834-1312',
    swtchCare: 'No',
    online: true,
    connector: { id: 1, connectorId: 1, serviceStatus: 'commissioned' },
    listing: { id: 1, title: 'L6652', initialActivationTime: null },
    uptimePercentage: 99.9,
    utilizationRate: 0.1,
    initialActivationDate: generatedMoment.format(),
  },
  {
    id: 2,
    chargePointSerialNumber: '1EC1-1-1834-00000',
    swtchCare: 'Yes',
    online: false,
    connector: { id: 2, connectorId: 2, serviceStatus: 'decommissioned' },
    listing: { id: 2, title: 'L6653', initialActivationTime: generatedMoment.subtract(6, 'day').format() },
    uptimePercentage: 90.1,
    utilizationRate: 0.9,
  },
] as ChargerUptime[]

export const mockChargersUptimeResponse = (
  tenantId: number,
  page: number,
  startDate: moment.Moment,
  endDate: moment.Moment,
) => {
  let filteredChargersUptime = mockChargersUptimeForTenant

  return {
    data: filteredChargersUptime,
    pagination: {
      currentPage: page,
      totalEntries: filteredChargersUptime.length,
      perPage: 20,
    },
  } as ChargersUptimeForTenantResponse
}

export const mockChargersPerTenantResponse = (filters: ChargersPerTenantFilter) => {
  log('fetching chargers mock', { filters })
  let filteredChargers = mockChargersV2

  if (filters.connectivityStatus === 'online') {
    filteredChargers = filteredChargers.filter((tenantData) =>
      tenantData.listings.some((listing) => listing.connectivity === true),
    )
  }

  if (filters.connectivityStatus === 'offline') {
    filteredChargers = filteredChargers.filter((tenantData) =>
      tenantData.listings.some((listing) => listing.connectivity === false),
    )
  }

  // Filter by listings selected
  if (filters.listings && filters.listings.length > 0) {
    const listingIds = filters.listings.map((listing) => listing.id)
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter((listing) => listingIds.includes(listing.id)),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  // Filter by chargers
  if (filters.chargers && filters.chargers.length > 0) {
    const chargerIds = filters.chargers.map((charger) => charger.id)
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter((listing) => listing.charger && chargerIds.includes(listing.charger.id)),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  // Filter by tenants
  if (filters.tenants && filters.tenants.length > 0) {
    const tenantIds = filters.tenants.map((tenant) => tenant.id)
    filteredChargers = filteredChargers.filter((tenantData) => tenantIds.includes(tenantData.tenant.id))
  }

  // Filter by vendors
  if (filters.vendors && filters.vendors.length > 0) {
    const vendorNames = filters.vendors
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter(
          (listing) => listing.charger && vendorNames.includes(listing.charger.chargePointVendor),
        ),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  // Filter by service statuses
  if (filters.serviceStatuses && filters.serviceStatuses.length > 0) {
    const serviceStatuses = filters.serviceStatuses
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter(
          (listing) =>
            listing.connector &&
            listing.connector.serviceStatus !== undefined &&
            serviceStatuses.includes(listing.connector.serviceStatus),
        ),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  // Filter by OCPP statuses
  if (filters.ocppStatuses && filters.ocppStatuses.length > 0) {
    const ocppStatuses = filters.ocppStatuses
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter(
          (listing) =>
            listing.connector &&
            listing.connector.ocppStatus !== undefined &&
            ocppStatuses.includes(listing.connector.ocppStatus),
        ),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }
  // Filter by taggings
  if (filters.taggings && filters.taggings.length > 0) {
    const taggingIds = filters.taggings.map((tagging) => tagging.id)
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter(
          (listing) => listing.tags && listing.tags && listing.tags.some((tag) => taggingIds.includes(tag.id)),
        ),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  return {
    data: filteredChargers,
    pagination: {
      currentPage: filters.page,
      totalEntries: filteredChargers
        .map((tenantData) => tenantData.listings.length)
        .reduce((acc, curr) => acc + curr, 0),
      perPage: 50,
    },
    filters: { vendors: mockVendors },
  } as ChargersResponse
}
