import { Button, Form, Modal } from 'antd'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { AccessPlanFooter, CreateAccessPlanModalWrapper } from 'atom/chargers'
import { Tenant } from 'models/tenant'
import { AccessTypeSectionModal } from './access-plan/access-type-section'
import { ScheduleSectionModal } from './access-plan/schedule-section'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { useAccessPlanCreateAndEdit } from 'hooks/useAccessPlanCreateAndEdit'
import { ConflictingAccessPlanModal } from './access-plan/conflicting-access-plan-modal'
import { AccessPlan } from 'models/access-plan'
import { NoAccessPlanLinkedModal } from './access-plan/no-access-plan-linked-modal'
import { AccessNameSectionModal } from './access-plan/access-name-section'
import { useEffect, useRef } from 'react'

interface props {
  visible: boolean
  tenant: Tenant
  accessPlans: AccessPlan[]
  selectedAccessPlan?: AccessPlan
  onOk: () => void
  onCancel: () => void
  setVisible: (visible: boolean) => void
  setSelectedAccessPlan: (setSelectedAccessPlan: AccessPlan | undefined) => void
}

export const CreateAndEditAccessPlanModal: React.FC<props> = ({
  visible,
  tenant,
  accessPlans,
  selectedAccessPlan,
  onCancel,
  setVisible,
  onOk,
  setSelectedAccessPlan,
}) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const {
    form,
    initialValues,
    accessCodeChecked,
    emailDomainChecked,
    emailDomains,
    accessType,
    driversChecked,
    scheduleType,
    isButtonDisabled,
    checkedDays,
    nameExists,
    initialCheckedState,
    showConflictModal,
    conflictingData,
    allListings,
    showNoAccessPlanModal,
    chargersNotAssignedData,
    listings,
    setAccessCodeChecked,
    setEmailDomainChecked,
    setEmailDomains,
    handleDayCheckedChange,
    handleAccessTypeChange,
    handleScheduleTypeChange,
    handleValuesChange,
    createAccessPlan,
    setAccessType,
    setScheduleType,
    nameCheck,
    setCheckedDays,
    setShowConflictModal,
    setCurrentPlanValues,
    updateAndSave,
    setListings,
    setShowNoAccessPlanModal,
    editAccessPlan,
    convertSingleDaySchedule,
    addListingsBack,
    setNameExists,
    continueAndClose,
  } = useAccessPlanCreateAndEdit(tenant, accessPlans, visible, selectedAccessPlan)
  const { createAnAccessPlanText } = useAccessPlanTranslation()
  const { createText, editText, saveText } = useGeneralTranslation()

  const afterClose = () => {
    setAccessType('restricted')
    setScheduleType('24/7')
    setEmailDomains([])
    setEmailDomainChecked(false)
    setAccessCodeChecked(false)
    setCheckedDays(initialCheckedState)
    setListings([])
    setSelectedAccessPlan(undefined)
    setNameExists(false)
    form.resetFields()
    onOk()
  }

  useEffect(() => {
    if (modalRef.current) {
      const modalContent = modalRef.current
      modalContent.scrollTop = 0
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={660}
      bodyStyle={{ padding: '0px' }}
      footer={
        <AccessPlanFooter>
          <Button key="submit" type="primary" disabled={isButtonDisabled || nameExists} onClick={() => form.submit()}>
            {selectedAccessPlan ? saveText : createText}
          </Button>
        </AccessPlanFooter>
      }
      title={
        <div className="heading-05-regular">
          {selectedAccessPlan ? `${editText} - ${selectedAccessPlan.name}` : createAnAccessPlanText}
        </div>
      }
      afterClose={afterClose}
    >
      <CreateAccessPlanModalWrapper>
        <Form
          form={form}
          layout="vertical"
          requiredMark={true}
          labelAlign="left"
          initialValues={initialValues}
          onValuesChange={handleValuesChange}
          onFinish={(values) => {
            if (selectedAccessPlan) {
              editAccessPlan(values, selectedAccessPlan.id, setVisible)
            } else {
              createAccessPlan(values, setVisible)
            }
            setCurrentPlanValues(values)
          }}
          labelWrap={true}
        >
          <div className="modal-body" ref={modalRef}>
            <AccessNameSectionModal
              tenant={tenant}
              selectedAccessPlan={selectedAccessPlan}
              nameExists={nameExists}
              listings={listings}
              allListings={allListings}
              nameCheck={nameCheck}
              setListings={setListings}
            />
            <AccessTypeSectionModal
              accessType={accessType}
              driversChecked={driversChecked}
              accessCodeChecked={accessCodeChecked}
              emailDomainChecked={emailDomainChecked}
              emailDomains={emailDomains}
              handleAccessTypeChange={handleAccessTypeChange}
              setAccessCodeChecked={setAccessCodeChecked}
              setEmailDomainChecked={setEmailDomainChecked}
              setEmailDomains={setEmailDomains}
            />
            <ScheduleSectionModal
              form={form}
              scheduleType={scheduleType}
              checkedDays={checkedDays}
              selectedAccessPlan={selectedAccessPlan}
              handleDayCheckedChange={handleDayCheckedChange}
              handleScheduleTypeChange={handleScheduleTypeChange}
              convertSingleDaySchedule={convertSingleDaySchedule}
            />
          </div>
        </Form>
      </CreateAccessPlanModalWrapper>
      <ConflictingAccessPlanModal
        visible={showConflictModal}
        conflictingData={conflictingData}
        onCancel={() => setShowConflictModal(false)}
        setVisible={setVisible}
        updateAndSave={updateAndSave}
      />
      {chargersNotAssignedData.length > 0 && (
        <NoAccessPlanLinkedModal
          visible={showNoAccessPlanModal}
          notLinkedCharger={chargersNotAssignedData}
          setVisible={setShowNoAccessPlanModal}
          setCloseModal={setVisible}
          continueAndClose={continueAndClose}
          undo={addListingsBack}
        />
      )}
    </Modal>
  )
}
