import { useFormatMessage } from '../../helpers/intl'

export const useAccessPlanTranslation = () => {
  const accessCodeText = useFormatMessage('dashboard.tenantPage.accesscode.heading', 'Access Code')
  const monText = useFormatMessage('dashboard.mobileCalendar.week.mon', 'Mon')
  const tueText = useFormatMessage('dashboard.mobileCalendar.week.tue', 'Tue')
  const wedText = useFormatMessage('dashboard.mobileCalendar.week.wed', 'Wed')
  const thuText = useFormatMessage('dashboard.mobileCalendar.week.thu', 'Thu')
  const friText = useFormatMessage('dashboard.mobileCalendar.week.fri', 'Fri')
  const satText = useFormatMessage('dashboard.mobileCalendar.week.sat', 'Sat')
  const sunText = useFormatMessage('dashboard.mobileCalendar.week.sun', 'Sun')
  const createAnAccessPlanText = useFormatMessage('dashboard.accessPlan.createAnAccessPlan', 'Create an Access Plan')
  const accessPlanNameText = useFormatMessage('dashboard.accessPlan.accessPlanName', 'Access Plan Name')
  const customDaysText = useFormatMessage('dashboard.accessPlan.customDays', 'Custom Days')
  const unrestrictedText = useFormatMessage('dashboard.accessPlan.unrestricted', 'Unrestricted')
  const noRestrictionsOnAccessText = useFormatMessage(
    'dashboard.accessPlan.noRestrictionsOnAccess',
    'No restrictions on access to charger',
  )
  const restrictedAccessText = useFormatMessage(
    'dashboard.accessPlan.restrictedAccess',
    'Restricted access via access code or email domain',
  )
  const restrictBasedOnText = useFormatMessage('dashboard.accessPlan.restrictBasedOn', 'Restrict Based On')
  const assignedDriversFromUsersText = useFormatMessage(
    'dashboard.accessPlan.assignedDriversFromUsers',
    'Assigned drivers from user page automatically get access. Change access on users page if needed.',
  )
  const usersGetAccessByAccessCodeText = useFormatMessage(
    'dashboard.accessPlan.usersGetAccessByAccessCode',
    'Users get access by entering this access code',
  )
  const usersGetAccessByEmailText = useFormatMessage(
    'dashboard.accessPlan.usersGetAccessByEmail',
    'Users get access by logging in with this email domain',
  )
  const emailDomainText = useFormatMessage('dashboard.accessPlan.emailDomain', 'Email Domain')
  const driversText = useFormatMessage('dashboard.accessPlan.drivers', 'Drivers')
  const nameToKeepTrackText = useFormatMessage('dashboard.accessPlan.nameToKeepTrack', 'A name to help you keep track')
  const chargerIdsText = useFormatMessage('dashboard.accessPlan.chargerIds', 'Charger ID(s)')
  const enterEmailDomainsText = useFormatMessage('dashboard.accessPlan.enterEmailDomains', 'Enter email domains')
  const addTimeScheduleText = useFormatMessage('dashboard.accessPlan.addTimeSchedule', 'ADD TIME SCHEDULE')
  const currentAssignedDriversText = useFormatMessage(
    'dashboard.accessPlan.currentAssignedDrivers',
    '-1 Assigned Drivers',
  )
  const accessPlanFlexText = useFormatMessage(
    'dashboard.accessPlan.accessPlanFlex',
    'Access plans allow you to control access to chargers by different times and/or by different groups of drivers',
  )
  const chargersNotLinkedText = useFormatMessage(
    'dashboard.accessPlan.chargersNotLinked',
    'Chargers Not Linked to Any Access Plans',
  )
  const assignedDriversText = useFormatMessage('dashboard.accessPlan.assignedDrivers', 'Assigned Drivers')
  const allAssignedText = useFormatMessage('dashboard.accessPlan.allAssigned', 'All Assigned')
  const addAccessPlanStartText = useFormatMessage(
    'dashboard.accessPlan.addAccessPlanStart',
    'Add an Access Plan to Start',
  )
  const conflictingAccessPlanText = useFormatMessage(
    'dashboard.accessPlan.conflictingAccessPlan',
    'Conflicting Access Plan',
  )
  const moveChargerText = useFormatMessage('dashboard.accessPlan.moveCharger', 'MOVE CHARGER')
  const chargersToolTipText = useFormatMessage(
    'dashboard.accessPlan.chargersToolTip',
    'Chargers that are not linked to an access plan will not be accessible at anytime.\nAdd these chargers to an existing access plan or create a new access plan to ensure drivers have access.',
  )
  const chargersOverlapText = useFormatMessage(
    'dashboard.accessPlan.chargersOverlap',
    'Chargers in this plan currently belong to existing Access Plans that have an overlapping schedule.',
  )
  const moveChargersText = useFormatMessage(
    'dashboard.accessPlan.moveChargers',
    'Move these chargers from all the existing conflicting Access Plans, or go back and adjust the schedule',
  )
  const accessPlanCreatedText = useFormatMessage('dashboard.accessPlan.accessPlanCreated', 'Access Plan Created')
  const accessPlanUpdatedText = useFormatMessage('dashboard.accessPlan.accessPlanUpdated', 'Access Plan Updated')

  return {
    accessCodeText,
    monText,
    tueText,
    wedText,
    thuText,
    friText,
    satText,
    sunText,
    createAnAccessPlanText,
    accessPlanNameText,
    customDaysText,
    unrestrictedText,
    noRestrictionsOnAccessText,
    restrictedAccessText,
    restrictBasedOnText,
    assignedDriversFromUsersText,
    usersGetAccessByAccessCodeText,
    usersGetAccessByEmailText,
    emailDomainText,
    driversText,
    nameToKeepTrackText,
    chargerIdsText,
    enterEmailDomainsText,
    addTimeScheduleText,
    currentAssignedDriversText,
    accessPlanFlexText,
    chargersNotLinkedText,
    assignedDriversText,
    allAssignedText,
    addAccessPlanStartText,
    conflictingAccessPlanText,
    moveChargerText,
    chargersToolTipText,
    chargersOverlapText,
    moveChargersText,
    accessPlanCreatedText,
    accessPlanUpdatedText,
  }
}
