import { User } from '../../models/user'
import { JWTIssue } from '../../models/auth'

export const returnMock = <T>(obj: T): Promise<T> => {
  return new Promise<T>((resolve) =>
    setTimeout(() => {
      return resolve(obj)
    }, 2000),
  )
}

export const mockAdminJwt = {
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIxIiwiaXNzIjoic3d0Y2giLCJhIjp0cnVlLCJleHAiOjE1MTYyMzkwMjJ9.6K-fLr5P-tBn2Ksm4kyuDh0JIX0VvwoovsfqkPjSrrk',
  expiresAt: 1516239022,
} as JWTIssue

export const mockSelf = {
  id: '1',
  name: 'demo',
  email: 'demomanager@swtchenergy.com',
  // role: 'admin',
  role: 'user',
  //role: 'support',
  accesses: [
    {
      resourceType: 'Tenant',
      resourceId: '76',
      permissions: ['tenant.manager'],
      display: '9-GreatNorth-Rd-ParrySound-ON-P2A2X8',
    },
    {
      resourceType: 'Tenant',
      resourceId: '68',
      permissions: ['tenant.viewer'],
      display: 'swtchhq',
    },
    {
      resourceType: 'Tenant',
      resourceId: '90',
      permissions: ['tenant.manager'],
      display: 'NewGold',
    },
  ],
} as User

export const mockTenantsRef = [
  {
    id: '1304',
    name: 'demoMFU-site1',
    displayName: 'Multi-Family Site 1',
    combineName: 'Multi-Family Site 1 (demoMFU-site1)',
  },
  {
    id: '64',
    name: 'demoMFU-site2',
    displayName: 'Multi-Family Site 2',
    combineName: 'Multi-Family Site 2 (demoMFU-site2)',
    chargerCount: 12,
  },
  {
    id: '2472',
    name: 'demoWorkplace-site1',
    displayName: 'Workplace/Commercial Site 1',
    combineName: 'Workplace/Commercial Site 1 (demoWorkplace-site1)',
    chargerCount: 10,
  },
  {
    id: '1466',
    name: 'demoDCFC-site1',
    displayName: 'DCFC Site 1',
    combineName: 'DCFC Site 1 (demoDCFC-site1)',
    chargerCount: 4,
  },
]

export const mockUserAddedToTenantsResponse = {
  tenantsAdded: ['swtchhq', '100-Victoria-St-S-Kitchener-ON-N2G2B3'],
  tenantsNotAdded: [],
}

export const mockDREventResponse = {
  status: 'failed',
  message: 'listing is not a reservation type',
}
