import { Button, Form, Modal, Select } from 'antd'
import { AugmentedUser, UserPermissions, UserRole, userRole } from '../../models/user'
import { useEffect, useState } from 'react'
import { TenantRef } from '../../models/tenant'
import { FindTenants } from '../../services/data-provider/tenants'
import { SwtchError } from '../../models/error'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import UserBasicInfo from './modal/UserBasicInfo'
import { NewUpdateUser } from '../../services/data-provider/users'
import { AlertError } from '../../components/error'
import EditForm from './modal/user-edit-form'
import { ButtonTextSpacing, FooterLayout, Heading } from '../../atom/user-edit'
import { useAppState } from '../../state'
import { StyledDrawer } from '../../atom/drawer'
import { MobileLineContainer } from '../../atom/user-invite'
import { capitalizeFirstLetter } from '../../helpers/users'
import { HorizontalLine } from '../../atom/horizontal-line'
import { DiscountSchema } from 'models/discount'
import { ActiveDiscountForUser, AddUsersToDiscounts, RemoveUsersFromDiscounts } from 'services/data-provider/discount'
import { LocalDiscount } from 'models/price'
import { useSelector } from 'hooks/useSelector'
import { useNotifications } from 'hooks/useNotification'

interface props {
  selectedUser: AugmentedUser
  user: AugmentedUser
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserEdit: () => void
}

interface TempObj {
  [key: number]: TenantRef[]
}

export const UserEditForm: React.FC<props> = ({ onCancel, selectedUser, onUserEdit }) => {
  const [form] = Form.useForm()
  const { siteLabelCleaner } = useSelector()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [tenantInputs, setTenantInputs] = useState(0)
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [tenantDropdownData, setTenantDropdownData] = useState<{ [index: number]: any }>({})
  const [userPermissions, setUserPermissions] = useState<UserPermissions[]>([])
  const [originalUserPermissions, setOriginalUserPermissions] = useState<UserPermissions[]>([])
  const [name, setName] = useState(selectedUser.name)
  const [note, setNote] = useState(selectedUser.note)
  const [selectedUserRole, setSelectedUserRole] = useState<UserRole>()
  const [disableSave, setDisableSave] = useState(true)
  const [changed, setChanged] = useState(false)
  const { editUserText, saveText, cancelText, updateText } = useUserTranslation()
  const { currentUser, IsMobile, isMockUpEnabled } = useAppState()
  const isMobile = IsMobile()
  const [idsWithOperatorRole, setIdsWithOperatorRole] = useState<string[]>([]) //id with operator role

  const [existingDiscounts, setExistingDiscounts] = useState<LocalDiscount[]>([])
  const { openErrorNotification, openSuccessNotification } = useNotifications()

  const permissionTransform = {
    'tenant.none': 'none',
    'tenant.viewer': 'viewer',
    'tenant.manager': 'manager',
    'tenant.charger_operator': currentUser?.role === 'admin' ? 'charger_operator' : 'manager',
  }

  const determineUserRole = () => {
    if (selectedUser.isAdmin) return 'admin'
    if (selectedUser.isSupport) return 'support'
    if (selectedUser.isUser) return 'user'
  }

  useEffect(() => {
    setSelectedUserRole(determineUserRole())
    setLoading(true)
    FindTenants(false, isMockUpEnabled)
      .then(async (tenants) => {
        const tenantsArr = tenants.filter((tenant) => tenant.combineName)
        setTenants(tenantsArr)

        const currentDiscount = await ActiveDiscountForUser(selectedUser.email, isMockUpEnabled)
        const parsedDiscount = currentDiscount.map(({ id, name }) => ({ id, name }))
        setExistingDiscounts(parsedDiscount)

        await handleUserAccess(tenantsArr, currentDiscount)
      })
      .catch((err: SwtchError) => {
        setTenants([])
        openErrorNotification(err.description)
      })
  }, [])

  useEffect(() => {
    const role = selectedUser.isAdmin ? 'admin' : selectedUser.isSupport ? 'support' : selectedUser.isUser ? 'user' : ''

    const canClickSaveButton =
      name !== selectedUser.name || note !== selectedUser.note || role !== selectedUserRole || changed

    if (canClickSaveButton) {
      setDisableSave(false)
    } else {
      setDisableSave(true)
    }
  }, [name, note, selectedUserRole, changed])

  const handleUserAccess = async (tenantData: TenantRef[], tenantDiscount: DiscountSchema[]) => {
    let objArr: any[] = []
    let match = 0
    let input = 0
    let tempObj: TempObj = {}
    let idWithOperatorRole: string[] = []
    selectedUser.accesses.forEach((access) => {
      return tenantData.forEach((tenant) => {
        if (tenant.id === access.resourceId) {
          match += 1
          input += 1
          tempObj[input] = [...tenantData]
          let driver = false
          let roles = 'none'

          access.permissions.forEach((permission) => {
            if (permission === 'tenant.driver') {
              driver = true
              return
            }
            if (roles === 'none') {
              roles = permissionTransform[permission]
            } else if (roles === 'viewer') {
              if (permission !== 'tenant.none') {
                roles = permissionTransform[permission]
              }
            } else if (roles === 'manager') {
              if (permission !== 'tenant.none' && permission !== 'tenant.viewer') {
                roles = permissionTransform[permission]
              }
            }
            if (permission === 'tenant.charger_operator') {
              idWithOperatorRole.push(tenant.id)
            }
          })
          const tenantName = siteLabelCleaner(
            `${access.displayName ? `${access.displayName} ` : ''}(${access.display || ''})`,
          )
          const obj = {
            name: tenantName,
            id: access.resourceId,
            access: roles,
            driver: driver,
          }
          objArr.push(obj)
          setTenantInputs(match)
          setTenantDropdownData({ ...tempObj })
        }
      })
    })
    tenantDiscount.forEach((discount) => {
      const discountTenantId = discount.tenantId
      const exitingTenantIndex = objArr.findIndex((obj: any) => obj.id === discountTenantId)
      if (exitingTenantIndex > -1) {
        objArr[exitingTenantIndex] = {
          ...objArr[exitingTenantIndex],
          discountPlan: [...(objArr[exitingTenantIndex]?.discountPlan ?? []), discount],
        }
      } else {
        const updatedTenantDataIndex = objArr.length + 1
        const newTenantData = tenantData.find((tenant) => parseFloat(tenant.id) === discount.tenantId)
        if (newTenantData) {
          tempObj[updatedTenantDataIndex] = [...tenantData]
          setTenantInputs(updatedTenantDataIndex)
          setTenantDropdownData({ ...tempObj })
        }
        objArr.push({
          access: 'none',
          driver: false,
          id: discountTenantId,
          discountPlan: [discount],
          name: newTenantData?.name,
        })
      }
    })
    setIdsWithOperatorRole(idWithOperatorRole)
    setUserPermissions(objArr)
    setOriginalUserPermissions(objArr)
    setLoading(false)
  }

  const handleUserRoleChange = (role: UserRole) => {
    setSelectedUserRole(role)
  }

  const afterClose = () => {
    form.resetFields()
  }
  const onOk = () => {
    let updateName = ''
    let updateNote = ''
    if (selectedUser.name !== name) updateName = name
    if (selectedUser.note !== note) updateNote = note
    const userToUpdate = {
      id: selectedUser.id,
      email: selectedUser.email,
      name: updateName,
      note: updateNote,
      role: selectedUserRole,
    }

    setLoading(true)
    const updatedUserPermissions = [...userPermissions]

    const updatedDiscounts: LocalDiscount[] = []

    userPermissions.forEach((user) => {
      //To give two permissions: manager and charger operator when the user is charger operator
      if (user.access === 'charger_operator') {
        updatedUserPermissions.push({ ...user, access: 'manager' })
      }

      //If the user is manager and didn't update the tenant with operator role
      if (currentUser?.role !== 'admin' && idsWithOperatorRole.includes(user.id)) {
        updatedUserPermissions.push({ ...user, access: 'charger_operator' })
      }

      user.discountPlan?.forEach((discount) => {
        updatedDiscounts.push({ id: discount.id, name: discount.name })
      })
    })

    const currentUserArray = [{ email: selectedUser?.email || '' }]

    if (existingDiscounts.length && updatedDiscounts.length) {
      RemoveUsersFromDiscounts(isMockUpEnabled, existingDiscounts, currentUserArray)
        .then((res) => {
          AddUsersToDiscounts(isMockUpEnabled, updatedDiscounts, currentUserArray)
            .then((res) => console.log('res', res))
            .catch((err) => setError(err))
        })
        .catch((err) => setError(err))
    } else if (existingDiscounts.length) {
      RemoveUsersFromDiscounts(isMockUpEnabled, existingDiscounts, currentUserArray)
        .then((res) => console.log('res', res))
        .catch((err) => setError(err))
    } else if (updatedDiscounts.length) {
      AddUsersToDiscounts(isMockUpEnabled, updatedDiscounts, currentUserArray)
        .then((res) => console.log('res', res))
        .catch((err) => setError(err))
    }

    NewUpdateUser(isMockUpEnabled, updatedUserPermissions, originalUserPermissions, userToUpdate)
      .then((resp) => {
        openSuccessNotification('Updated info')
        return onUserEdit()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <AlertError error={error} />
      {!isMobile ? (
        <Modal
          title={<Heading>{editUserText}</Heading>}
          closable={true}
          okText={updateText}
          cancelText={cancelText}
          visible={true}
          onCancel={onCancel}
          onOk={onOk}
          afterClose={afterClose}
          width={543}
          className="fixed-row-modal"
          footer={
            <FooterLayout>
              <Button style={{ width: '240px' }} loading={loading} onClick={onCancel}>
                <span className="paragraph-02-regular">{cancelText}</span>
              </Button>
              <Button style={{ width: '240px' }} type="primary" loading={loading} onClick={onOk} disabled={disableSave}>
                <ButtonTextSpacing disabled={disableSave}>
                  <span className="paragraph-02-regular">{saveText}</span>
                </ButtonTextSpacing>
              </Button>
            </FooterLayout>
          }
        >
          {currentUser?.role === 'admin' && (
            <>
              <Select
                style={{ width: '100%' }}
                value={selectedUserRole}
                onChange={(value) => handleUserRoleChange(value)}
              >
                {userRole.map((role) => (
                  <Select.Option value={role}>{capitalizeFirstLetter(role)}</Select.Option>
                ))}
              </Select>
              <HorizontalLine />
            </>
          )}
          <UserBasicInfo editUser={selectedUser} note={note} name={name} setName={setName} setNote={setNote} />
          <EditForm
            tenantInputs={tenantInputs}
            tenants={tenants}
            tenantDropdownData={tenantDropdownData}
            userPermissions={userPermissions}
            loading={loading}
            setTenantInputs={setTenantInputs}
            setTenantDropdownData={setTenantDropdownData}
            setUserPermissions={setUserPermissions}
            setChanged={setChanged}
            idsWithOperatorRole={idsWithOperatorRole}
            setIdsWithOperatorRole={setIdsWithOperatorRole}
          />
        </Modal>
      ) : (
        <StyledDrawer
          placement="bottom"
          closable={false}
          onClose={onCancel}
          visible={true}
          key="bottom"
          footer={
            <Button style={{ width: '100%' }} type="primary" loading={loading} onClick={onOk} disabled={disableSave}>
              <ButtonTextSpacing disabled={disableSave}>
                <span className="paragraph-02-regular">{saveText}</span>
              </ButtonTextSpacing>
            </Button>
          }
          footerStyle={{ boxShadow: '0px -3px 5px 0px rgba(0, 0, 0, 0.06)', padding: '25px 15px' }}
        >
          <MobileLineContainer />
          <Heading>{editUserText}</Heading>
          <HorizontalLine />
          {currentUser?.role === 'admin' && (
            <>
              <Select
                style={{ width: '100%' }}
                value={selectedUserRole}
                onChange={(value) => handleUserRoleChange(value)}
              >
                {userRole.map((role) => (
                  <Select.Option value={role}>{capitalizeFirstLetter(role)}</Select.Option>
                ))}
              </Select>
              <HorizontalLine />
            </>
          )}
          <UserBasicInfo editUser={selectedUser} note={note} name={name} setName={setName} setNote={setNote} />
          <EditForm
            tenantInputs={tenantInputs}
            tenants={tenants}
            tenantDropdownData={tenantDropdownData}
            userPermissions={userPermissions}
            loading={loading}
            setTenantInputs={setTenantInputs}
            setTenantDropdownData={setTenantDropdownData}
            setUserPermissions={setUserPermissions}
            setChanged={setChanged}
            idsWithOperatorRole={idsWithOperatorRole}
            setIdsWithOperatorRole={setIdsWithOperatorRole}
          />
        </StyledDrawer>
      )}
    </>
  )
}
