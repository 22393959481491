import moment from 'moment'
import { FormattedDate } from 'react-intl'

import { Transaction } from '../models/transaction'
import { DateTimeV2 } from '../components/date-time'
import NoDataBadge from '../atom/Badge'

export const formatDate = (date: string) => <FormattedDate value={date} year="numeric" month="short" day="2-digit" />

export const formatMomentDate = (date: string | moment.Moment | undefined, format: string) =>
  moment(date).format(format)

export const renderChargingStop = (transaction: Transaction) => {
  const { chargeStartAt, chargeEndAt, timezone, duration, currentState } = transaction
  if (!chargeEndAt && (currentState === 'errored' || currentState === 'canceled')) {
    return '-'
  }

  if (chargeEndAt && chargeStartAt) {
    return <DateTimeV2 date={moment(chargeEndAt)} timezone={timezone} isSidePanel />
  } else if (chargeEndAt && !chargeStartAt) {
    return <DateTimeV2 date={moment(chargeEndAt)} timezone={timezone} isSidePanel />
  } else if (!chargeEndAt && chargeStartAt && duration) {
    return <DateTimeV2 date={moment(chargeStartAt).add(duration, 'seconds')} timezone={timezone} isSidePanel />
  } else {
    return <NoDataBadge noBackground />
  }
}

export const convertMinutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  const period = hours >= 12 ? 'pm' : 'am'
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12
  const formattedMinutes = mins.toString().padStart(2, '0')
  return `${formattedHours}:${formattedMinutes}${period}`
}
