import { Select } from 'antd'
import { EmailDomainSelectorWrapper } from 'atom/chargers'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'

interface props {
  disabled: boolean
  emailDomains: string[]
  setEmailDomains: (emailDomains: string[]) => void
}

const EmailDomainInput: React.FC<props> = ({ disabled, emailDomains, setEmailDomains }) => {
  const { enterEmailDomainsText } = useAccessPlanTranslation()

  const handleChange = (value: string[]) => {
    const formattedValues = value.map((item) => `@${item.replace(/^@/, '')}`)
    setEmailDomains(formattedValues)
  }

  return (
    <EmailDomainSelectorWrapper>
      <Select
        disabled={disabled}
        mode="tags"
        style={{ width: '100%' }}
        placeholder={enterEmailDomainsText}
        value={emailDomains}
        onChange={handleChange}
        tokenSeparators={[',']}
        open={false}
      />
    </EmailDomainSelectorWrapper>
  )
}

export default EmailDomainInput
