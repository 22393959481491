import React, { ReactElement } from 'react'
import { Button, Form, Select, Switch, Tooltip } from 'antd'
import { SwtchError } from '../../../models/error'
import { TenantRef } from '../../../models/tenant'
import { UserPermissions } from '../../../models/user'
import { theme } from '../../../theme'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { addSVG } from '../../../assets/svg/add'
import { AlertError } from '../../../components/error'
import { AddMoreWrapper, Body, SvgSpacing, SwitchLabel, TenantInputBody } from '../../../atom/user-invite'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'
import { DropdownWrapper, SelectWrapper } from '../../../atom/user-edit'
import { HorizontalLine } from '../../../atom/horizontal-line'
import { useSelector } from 'hooks/useSelector'

const { Option } = Select

interface props {
  userPermissions: UserPermissions[]
  error: SwtchError | undefined
  tenantDropdownData: {
    1: TenantRef[]
  }
  userPermissionInfo: ('none' | 'manager' | 'viewer' | 'charger_operator')[]
  roleTextTransform: {
    none: string
    manager: string
    charger_operator: string
    viewer: string
  }
  tenantInputs: number
  tenants: TenantRef[]
  handleTenantChange: (value: string, index: number) => void
  handleRoleChange: (value: string, index: number) => void
  handleDriverChange: (index: number) => void
  setUserPermissions: (value: React.SetStateAction<UserPermissions[]>) => void
  setTenantInputs: (value: React.SetStateAction<number>) => void
  setTenantDropdownData: (
    value: React.SetStateAction<{
      1: TenantRef[]
    }>,
  ) => void
}

export const UserInviteTenants: React.FC<props> = ({
  userPermissions,
  error,
  tenantDropdownData,
  userPermissionInfo,
  roleTextTransform,
  tenantInputs,
  tenants,
  handleTenantChange,
  handleRoleChange,
  handleDriverChange,
  setUserPermissions,
  setTenantInputs,
  setTenantDropdownData,
}) => {
  const [form] = Form.useForm()
  const { siteLabelCleaner } = useSelector()
  const { role2Text, tenantsInviteText, driverInviteText, tooltipInviteText, addTenantsText } = useUserTranslation()

  const renderOption = (tenant: TenantRef, index: any): ReactElement => {
    return (
      <Option style={{ marginTop: '10px' }} key={tenant.id} value={siteLabelCleaner(tenant.combineName)}>
        <DropdownWrapper selected={userPermissions[index].name === siteLabelCleaner(tenant.combineName)}>
          {siteLabelCleaner(tenant.combineName)}
        </DropdownWrapper>
      </Option>
    )
  }

  return (
    <>
      <AlertError error={error} />
      <Form form={form} labelAlign="right" layout="vertical">
        <HorizontalLine />
        <TenantInputBody>
          <Body>
            <Form.Item className="tenant-select" label={tenantsInviteText}>
              <SelectWrapper>
                <Select
                  dropdownMatchSelectWidth={false}
                  showSearch
                  onChange={(value) => handleTenantChange(value, 0)}
                  value={userPermissions[0].name}
                >
                  {tenantDropdownData[1].map((v) => renderOption(v, 0))}
                </Select>
              </SelectWrapper>
            </Form.Item>
            <Form.Item className="role-select" key="role" label={role2Text} name={0}>
              <SelectWrapper>
                <Select onChange={(value) => handleRoleChange(value, 0)}>
                  {userPermissionInfo.map((role) => (
                    <Select.Option key={role} value={role}>
                      <DropdownWrapper selected={userPermissions[0].access === role}>
                        {roleTextTransform[role]}
                      </DropdownWrapper>
                    </Select.Option>
                  ))}
                </Select>
              </SelectWrapper>
            </Form.Item>
            <Form.Item
              className="switch"
              key="driver"
              label={
                <>
                  {driverInviteText}
                  <Tooltip overlayInnerStyle={{ borderRadius: '5px' }} placement="top" title={tooltipInviteText}>
                    <ExclamationCircleFilled
                      style={{ fontSize: theme.fontSizes[3], marginLeft: '5px', color: theme.colors.lightGray }}
                    />
                  </Tooltip>
                </>
              }
              name="driver"
            >
              <div>
                <SwitchLabel>Off</SwitchLabel>
                <Switch
                  style={{
                    marginLeft: '5px',
                    marginRight: '5px',
                    backgroundColor: userPermissions[0].driver ? theme.colors.neonGreen : '#bfbfbf',
                  }}
                  onChange={() => handleDriverChange(0)}
                ></Switch>
                <SwitchLabel>On</SwitchLabel>
              </div>
            </Form.Item>
          </Body>
          {tenantInputs &&
            [...Array(tenantInputs)].map((tenantInput, tenantInputIndex) => {
              return (
                <Body key={tenantInputIndex}>
                  {tenantInputIndex > 0 && (
                    <>
                      <Form.Item className="tenant-select">
                        <Select
                          dropdownMatchSelectWidth={false}
                          showSearch
                          onChange={(value) => handleTenantChange(value, tenantInputIndex)}
                          value={userPermissions[tenantInputIndex].name}
                        >
                          {tenantDropdownData[tenantInputIndex as keyof typeof tenantDropdownData]
                            .map((v) => renderOption(v, tenantInputIndex))
                            .sort(
                              (a: ReactElement, b: ReactElement) =>
                                b.props.children.props.checked - a.props.children.props.checked,
                            )}
                        </Select>
                      </Form.Item>
                      <Form.Item className="role-select" key="role">
                        <SelectWrapper>
                          <Select onChange={(value) => handleRoleChange(value, tenantInputIndex)}>
                            {userPermissionInfo.map((role) => (
                              <Select.Option key={role} value={role}>
                                <DropdownWrapper selected={userPermissions[tenantInputIndex].access === role}>
                                  {roleTextTransform[role]}
                                </DropdownWrapper>
                              </Select.Option>
                            ))}
                          </Select>
                        </SelectWrapper>
                      </Form.Item>
                      <Form.Item className="switch" key="driver" name="driver">
                        <SwitchLabel>Off</SwitchLabel>
                        <Switch
                          style={{
                            marginLeft: '5px',
                            marginRight: '5px',
                            backgroundColor: userPermissions[tenantInputIndex].driver
                              ? theme.colors.neonGreen
                              : '#bfbfbf',
                          }}
                          onChange={() => handleDriverChange(tenantInputIndex)}
                        ></Switch>
                        <SwitchLabel>On</SwitchLabel>
                      </Form.Item>
                    </>
                  )}
                </Body>
              )
            })}
        </TenantInputBody>
        <AddMoreWrapper>
          <Form.Item className="add-more-button">
            <Button
              style={{
                color: theme.colors.neonGreen,
                padding: 0,
                height: 'fit-content',
                marginBottom: '0px',
                letterSpacing: '0.05em',
              }}
              type="link"
              onClick={() => {
                setUserPermissions([...userPermissions, { name: '', id: '', access: '', driver: false }])
                setTenantInputs((currentTenantInputs) => currentTenantInputs + 1)
                setTenantDropdownData({ ...tenantDropdownData, [tenantInputs + 1]: [...tenants] })
              }}
            >
              <SvgSpacing>{addSVG}</SvgSpacing>
              {addTenantsText}
            </Button>
          </Form.Item>
        </AddMoreWrapper>
      </Form>
    </>
  )
}
