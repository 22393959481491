import { ListingAccess } from 'models/listing'

type PermissionType = 'publicHidden' | 'publicVisible' | 'privateHidden' | 'privateVisible'

export const mockPermission: { [key in PermissionType]: ListingAccess } = {
  publicHidden: {
    accessType: 'public',
    hidden: true,
    accessCode: 123456,
  },
  publicVisible: {
    accessType: 'public',
    hidden: false,
    accessCode: 123456,
  },
  privateHidden: {
    accessType: 'private',
    hidden: true,
    accessCode: '123ABC',
  },
  privateVisible: {
    accessType: 'private',
    hidden: false,
    accessCode: 123456,
  },
}
