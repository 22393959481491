import { useEffect } from 'react'
import { Divider, Form, Modal } from 'antd'
import { HeadingWrapper } from '../../../atom/plans'
import { Tenant } from '../../../models/tenant'
import { CreatePricingModalWrapper } from '../../../atom/price'
import { PricingSchema } from '../../../models/price'
import { ChargingSectionModal } from './charging-section'
import { LoiteringSectionModal } from './loitering-section'
import { ConstantsSectionModal } from './constants-section'
import { FooterSectionModal } from './footer-section'
import { usePricingSchemas } from '../../../hooks/usePricingSchemas'
import { NameSectionModal } from './name-section'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'

interface props {
  tenant: Tenant
  selectedPricingSchema: PricingSchema
  onCancel: () => void
  onOk: () => void
}

const EditPricingSchemaModal: React.FC<props> = ({ tenant, selectedPricingSchema, onCancel, onOk }) => {
  const [form] = Form.useForm()
  const {
    loading,
    initialValues,
    nameExists,
    eventSchedules,
    timeOfDay,
    timeOfDayLoitering,
    enableCurtailmentAdjustment,
    loiteringType,
    allListings,
    pricingType,
    listings,
    setLoiteringType,
    setEnableCurtailmentAdjustment,
    setTimeOfDay,
    setTimeOfDayLoitering,
    getPricingNames,
    nameCheck,
    setPricePerUnitLoitering,
    setPricePerUnit,
    onFinish,
    setTimeOfDayPricingWeekday,
    setTimeOfDayPricingWeekend,
    setTimeOfDayPricingWeekDayLoitering,
    setTimeOfDayPricingWeekendLoitering,
    setListings,
    setAllListings,
    setPricingType,
  } = usePricingSchemas(tenant, onOk, onCancel, selectedPricingSchema)
  const { pricingPlanText } = useGeneralTranslation()

  useEffect(() => {
    return () => {}
  }, [])

  const afterClose = () => form.resetFields()

  useEffect(() => {
    getPricingNames(Number(tenant.id))
    const pricingArr = Array.from({ length: 48 }, () => Number(initialValues.dollar_rate) * 100)
    setTimeOfDayPricingWeekday(pricingArr)
    setTimeOfDayPricingWeekend(pricingArr)
    const pricingLoiteringArr = Array.from({ length: 48 }, () => Number(initialValues.loitering_dollar_rate) * 100)
    setTimeOfDayPricingWeekDayLoitering(pricingLoiteringArr)
    setTimeOfDayPricingWeekendLoitering(pricingLoiteringArr)
  }, [])

  const { loiteringText } = usePricingSchemasTranslation()

  return (
    <Modal
      title={<HeadingWrapper>{pricingPlanText}</HeadingWrapper>}
      visible={true}
      onCancel={onCancel}
      width={900}
      confirmLoading={loading}
      afterClose={afterClose}
      footer={null}
      style={{ top: 20 }}
    >
      <CreatePricingModalWrapper>
        <Form
          colon={false}
          form={form}
          requiredMark={true}
          labelAlign="left"
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, 'editPricingSchema', listings)}
          labelWrap={true}
        >
          <NameSectionModal
            tenant={tenant}
            selectedPricingSchema={selectedPricingSchema}
            nameExists={nameExists}
            allListings={allListings}
            type="edit"
            nameCheck={nameCheck}
            getPricingNames={getPricingNames}
            setListings={setListings}
            setAllListings={setAllListings}
          />
          <ChargingSectionModal
            form={form}
            timeOfDay={timeOfDay}
            eventSchedules={eventSchedules}
            enableCurtailmentAdjustment={enableCurtailmentAdjustment}
            priceType={pricingType}
            setPricePerUnit={setPricePerUnit}
            setTimeOfDay={setTimeOfDay}
            setEnableCurtailmentAdjustment={setEnableCurtailmentAdjustment}
            setPriceType={setPricingType}
          />

          <Divider>
            <span className="paragraph-02-bold">{loiteringText}</span>
          </Divider>

          <LoiteringSectionModal
            form={form}
            loiteringType={loiteringType}
            timeOfDayLoitering={timeOfDayLoitering}
            eventSchedules={eventSchedules}
            setPricePerUnitLoitering={setPricePerUnitLoitering}
            setTimeOfDayLoitering={setTimeOfDayLoitering}
            setLoiteringType={setLoiteringType}
          />
          <ConstantsSectionModal />

          <FooterSectionModal loading={loading} form={form} nameExists={nameExists} type="edit" onCancel={onCancel} />
        </Form>
      </CreatePricingModalWrapper>
    </Modal>
  )
}

export default EditPricingSchemaModal
