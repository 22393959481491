import { Button, Form } from 'antd'

import { useListingBasicTrans } from '../../hooks/translation/useListingBasicTrans'
import { useListingServiceStatus } from '../../hooks/useListingServiceStatus'
import { ListingStatusUpdate, NewListing } from '../../models/listing'
import { useAppState } from '../../state'

import { ReminderModal } from 'features/listings/modal/ReminderModal'
import { layout } from '../../atom/form/modal-layout'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { AlertError } from '../error'
import { AboutSelectedListingSection } from './listingServiceStatusSections/about-selected-listing-section'
import { IssueDetailsSection } from './listingServiceStatusSections/issue-details-section'
import { ServiceEventSection } from './listingServiceStatusSections/service-event-section'
import { StatusChangeSection } from './listingServiceStatusSections/status-change-section'
import moment from 'moment'

interface props {
  listings: NewListing[]
  onOk: () => void
  onApply?: (values: ListingStatusUpdate) => void
}

export const UpdateListingServiceStatus: React.FC<props> = ({ listings, onOk, onApply }) => {
  const { language } = useAppState()

  const {
    error,
    loading,
    openDecommisionedModal,
    onServiceStatusChange,
    decommisionedModalOnFinish,
    showAlertIfDecommissioned,
    closeDecommisionedModal,
    form,
    isDisableStatus,
    displayStatusDetail,
    newStatus,
    statusChangable,
    currentReasons,
    serviceDescriptions,
    listingWithLatestStatusChanged,
    selectRef,
    handleSelect,
    onReasonChange,
    dependentSelectorValue,
    secondDropdownOptions,
    showOtherReasonField,
    isSameStatus,
    isSameTenant,
    hasSpecificServiceStatuses,
    initialActivationWarning,
  } = useListingServiceStatus(listings, onOk, onApply)

  const { applyText } = useGeneralTranslation()
  const {
    decommissionedDescription,
    decommissionedOption1Left,
    decommissionedOption1Right,
    decommissionedOption2Left,
    decommissionedOption2Right,
  } = useListingBasicTrans()

  const chooseLayout = () => {
    let frLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 24 },
    }
    return language === 'fr' ? frLayout : layout
  }

  const decommisionModalOptions = [
    {
      left: decommissionedOption1Left,
      right: decommissionedOption1Right,
    },
    {
      left: decommissionedOption2Left,
      right: decommissionedOption2Right,
    },
  ]

  const shouldDisplaySection = (status: string) => {
    return newStatus === status && displayStatusDetail(newStatus)
  }

  return (
    <>
      <AlertError error={error} />
      <ReminderModal
        onOk={decommisionedModalOnFinish}
        visible={openDecommisionedModal}
        onCancel={closeDecommisionedModal}
        description={decommissionedDescription}
        options={decommisionModalOptions}
      />
      <Form
        form={form}
        {...chooseLayout()}
        onFinish={showAlertIfDecommissioned}
        initialValues={{
          status_changed_since:
            listingWithLatestStatusChanged !== null
              ? listingWithLatestStatusChanged.serviceStatusChangedSince
              : moment(),
          uptime_report: 'include',
        }}
      >
        <>
          <AboutSelectedListingSection
            loading={loading}
            selectedListings={listings}
            newStatus={newStatus}
            displayStatusDetail={displayStatusDetail}
          />
          <StatusChangeSection
            {...{
              onServiceStatusChange,
              isDisableStatus,
              loading,
              newStatus,
              displayStatusDetail,
              currentReasons,
              serviceDescriptions,
              selectRef,
              handleSelect,
              onReasonChange,
              dependentSelectorValue,
              secondDropdownOptions,
              showOtherReasonField,
              isSameStatus,
              isSameTenant,
              shouldDisplaySection,
              hasSpecificServiceStatuses,
              initialActivationWarning,
            }}
            status_changed_since={
              listingWithLatestStatusChanged !== null
                ? listingWithLatestStatusChanged.serviceStatusChangedSince
                : moment()
            }
            showWarning={statusChangable}
          />

          {shouldDisplaySection('under_repair') && (
            <IssueDetailsSection loading={loading} currentReasons={currentReasons} />
          )}
          {shouldDisplaySection('active') && (
            <ServiceEventSection
              loading={loading}
              currentReasons={currentReasons}
              serviceDescriptions={serviceDescriptions}
            />
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {applyText}
            </Button>
          </Form.Item>
        </>
      </Form>
    </>
  )
}
