import { DownOutlined, EditFilled } from '@ant-design/icons'
import { Dropdown, Menu, Space } from 'antd'

import EVChargerSVG from 'assets/svg/ev_charging'
import { plugGreySVG } from 'assets/svg/plug'
import { Links } from 'components/routes/paths'

import { renderFormatMessage } from 'helpers/intl'
import { buildRailsListingUrl } from 'helpers/url'

import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'
import { useListingsTranslation } from 'hooks/translation/useListingsTranslation'
import { NewListing } from 'models/listing'

import { useAppState } from 'state'
import { theme } from 'theme'

const menuStyle = {
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.frenchGray}`,
  borderRadius: '5px',
}

const { protocol, hostname } = window.location

const ActionsMenu = ({ listing }: { listing: NewListing }) => {
  const { currentUser, isMockUpEnabled } = useAppState()
  const isAdmin = currentUser?.role === 'admin'

  const { viewChargerInAppText } = useChargerTranslation()
  const { viewChargerDetailsText } = useChargerTranslation()
  const { editListingDetailsText } = useListingsTranslation()

  if (!listing) return null

  const mockChargerBasedOnEnvironment = () => {
    let chargerId = 68
    let connectorId = 85
    if (window.location.hostname.includes('staging.admin.swtchenergy.com')) {
      // ! Charger 5SW2-1-2141-00091 (FR05)
      chargerId = 68
      connectorId = 85
    } else if (window.location.hostname.includes('preprod.admin.swtchenergy.com')) {
      // ! Charger 1EC3-1-1917-00010 (FR02)
      chargerId = 241
      connectorId = 269
    } else if (window.location.hostname.includes('admin.swtchenergy.com')) {
      // ! using charger AXLU111001D1S1V2238A093A0 (P5342) for demo purposes
      chargerId = 4506
      connectorId = 827
    }
    return [chargerId, connectorId]
  }

  const renderChargerDetailsLink = () => {
    if (listing.charger?.id && listing.connector?.id) {
      const [chargerId, connectorId] = mockChargerBasedOnEnvironment()
      return isMockUpEnabled ? (
        <a
          key={`${listing.id}-charger`}
          href={`${protocol}//${hostname}/chargers/${chargerId}/${connectorId}`}
          target="_blank"
          rel="noreferrer"
          aria-label={`View details for charger ${listing.charger.id}`}
        >
          {viewChargerDetailsText}
        </a>
      ) : (
        <a
          key={`${listing.id}-charger`}
          href={Links.charger({
            chargerId: listing.charger.id,
            connectorId: listing.connector.id,
          })}
          target="_blank"
          rel="noreferrer"
          aria-label={`View details for charger ${listing.charger.id}`}
        >
          {viewChargerDetailsText}
        </a>
      )
    }
    return <span>{viewChargerDetailsText}</span>
  }

  const renderChargerStatusLink = () => {
    if (listing.id) {
      return (
        <a
          style={{ marginLeft: '0.5rem' }}
          key={`${listing.id}-${listing.title}`}
          href={buildRailsListingUrl(listing)}
          target="_blank"
          rel="noreferrer"
          aria-label={`View charger status for listing ${listing.title}`}
        >
          {viewChargerInAppText}
        </a>
      )
    }
    return <span style={{ marginLeft: '0.5rem' }}>{viewChargerInAppText}</span>
  }

  const renderEditLink = () => {
    if (listing.id) {
      return (
        <a
          key={`${listing.id}-${listing.title}`}
          href={Links.listing({ listingId: listing.id })}
          target="_blank"
          rel="noreferrer"
        >
          {editListingDetailsText}
        </a>
      )
    }
    return <span>{editListingDetailsText}</span>
  }

  const menu = (
    <Menu style={menuStyle}>
      <Menu.Item
        disabled={!listing.charger || !listing.connector}
        aria-disabled={!listing.charger || !listing.connector}
        icon={plugGreySVG}
      >
        {renderChargerDetailsLink()}
      </Menu.Item>
      <Menu.Item disabled={!listing.id} icon={<EVChargerSVG color={theme.colors.darkGray} />}>
        {renderChargerStatusLink()}
      </Menu.Item>
      {isAdmin ? (
        <Menu.Item
          disabled={!listing.id}
          icon={<EditFilled style={{ fontSize: theme.fontSizes[3], color: theme.colors.darkGray }} />}
        >
          {renderEditLink()}
        </Menu.Item>
      ) : null}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} overlayClassName="custom-dropdown" aria-haspopup="true" aria-controls="actions-menu">
      <span onClick={(e) => e.preventDefault()} role="button" tabIndex={0} aria-label="More actions">
        <Space>
          <span style={{ color: theme.colors.softBlack, textTransform: 'uppercase' }}>
            {renderFormatMessage('dashboard.text.more', 'More')}
          </span>
          <DownOutlined style={{ color: theme.colors.softBlack }} />
        </Space>
      </span>
    </Dropdown>
  )
}

export default ActionsMenu
